import autosize from "autosize";
import "bootstrap-material-design";
import "jquery-circle-progress";
import "jquery-validation";

import "./style";
import "./style_plus";
import "./mon_classement";
import "./Notifications.class";

window.autosize = autosize;
