var Notifications = {
    /**
     * Définie les autorisation de notifications
     * default: l'utilisateur n'a pas répondu à la demande
     * denied: l'utilisateur à bloqué les notifications
     * granted: l'utilisateur à autorisé les notifications
     * @var {'granted'|'default'|'denied'}
     */
    granted: "default",

    /**
     * Vérifie si les services worker et les push API
     * sont supportés
     * @return {boolean}
     */
    isSupported: function() {
        return "serviceWorker" in navigator && "PushManager" in window;
    },

    /**
     * Affiche la demande d'autorisation de notification et renvoie le résultat
     * @return {Promise<'granted'|'default'|'denied'>}
     */
    askPermission: function() {
        return Notification.requestPermission().then(function(result) {
            Notifications.granted = result;
        });
    },

    /**
     * Demande la clef public au serveur et la renvoie
     * soit en base64 soit en brut dans un ArrayBuffer
     * @param {boolean} raw Si vrai alors renvoie un ArrayBuffer sinon un string
     * @return {Promise<string|ArrayBuffer>} La clef public soit en b64 soit en brut dans un ArrayBuffer
     */
    requestPublicKey: function(raw) {
        /**
         * On récupère la clef public du serveur pour que mozilla/google
         * puissent vérifier que les notifs ne proviènnent pas d'un autre serveur
         */
        return new Promise(function(resolve, reject) {
            $.ajax("index.php?rub=4&sp=1", { async: true, dataType: "json" })
                .then(function(publicKey) {
                    if (!raw) {
                        resolve(publicKey);
                    } else {
                        /**
                         * On doit convertir la clef publique qui est encodé en base64
                         * vert un ArrayBuffer
                         */
                        var base64Key = publicKey.replace(/\-/g, "+").replace(/\_/g, "/");
                        var rawKey = atob(base64Key);
                        var keyBytes = new Uint8Array(rawKey.length);
                        for (var i = 0; i < rawKey.length; i++) {
                            keyBytes[i] = rawKey.charCodeAt(i);
                        }
                        resolve(keyBytes.buffer); // Notre ArrayBuffer à envoyer au PushManager
                    }
                })
                .fail(function(xhr, text, error) {
                    reject(error);
                });
        });
    },

    /**
     * Active les notifications coté serveur
     */
    enableNotifications: function() {
        if (this.granted == "denied") {
            // Si l'utilisateur à bloqué les notifs on déclenche une erreur
            throw new Error("Vous avez bloqué les notifications");
        } else if (this.granted == "default") {
            /**
             * Si l'utilisateur n'a rien fait on redemande les autorisations
             * Avant de relancer la fonction d'activation
             */

            this.askPermission().then(this.enableNotifications);
            return;
        } else {
            /**
             * On doit enregistrer notre service worker car c'est lui
             * qui recevra les notifications quand le site sera fermé
             */
            navigator.serviceWorker
                .register("/dist/sw.js")
                .then(function(registration) {
                    registration.update();
                    // On demande la clef public en brut dans un ArrayBuffer
                    return Notifications.requestPublicKey(true).then(function(publicKey) {
                        return {
                            registration: registration,
                            publicKey: publicKey
                        };
                    });
                })
                .then(function(data) {
                    /**
                     * On se retrouve avec la registration du worker et de la cle publique
                     * On subscribe le pushmanager pour en récupérer une souscription
                     * qui sera envoyé au serveur et stocké
                     */
                    return data.registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: data.publicKey
                    });
                })
                .then(function(pushSubscription) {
                    $.ajax("index.php?rub=4&sp=2", {
                        method: "POST",
                        data: {
                            subscription: JSON.stringify(pushSubscription)
                        }
                    })
                        .then(function(result) {
                            // console.log(result);
                        })
                        .fail(function(xhr, text, error) {
                            console.error(error);
                        });
                })
                .catch(function(error) {
                    console.error(error);
                });
        }
    }
};

window.Notifications = Notifications;
