/********************************/
/*********** TEAM UP ************/
/********************************/

$(document).ready(function() {
    /* Circle progress bar Mon classement */
    var nbTotalPoints = $(".circle").data("nbtotalpoints");

    var nbPointsUser = $(".circle").data("nbpoints");
    var pourcentageCircle = $(".circle").data("pourcentage") / 100;

    /*var pourcentageCircle = nbPointsUser / nbTotalPoints;*/

    var progressBarOptions = {
        startAngle: -1.55,
        size: 200,
        value: pourcentageCircle,
        fill: {
            color: "#4BB4E6"
        }
    };

    //activation de l'animation du cercle
    $(".circle")
        .circleProgress(progressBarOptions)
        .on("circle-animation-progress", function(event, progress, stepValue) {
            $(this)
                .find("strong")
                .text(String(Math.round(stepValue * nbTotalPoints)));
        });

    /* Gestion progress bar mon classement */
    $(".progress-bar").width("0%");
    $(".tooltipCircle").css("left", "0%");

    $(".progress-bar").each(function() {
        var each_bar_width = $(this).attr("aria-valuenow");
        $(this).width(each_bar_width + "%");
        $(".tooltipCircle").css("left", "calc(100% - 5px)");
    });
});
